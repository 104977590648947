import { useFormatters } from '@/hooks/useFormatters';
import { useLingui } from '@lingui/react';
import * as echarts from 'echarts';
import 'echarts/i18n/langEN';
import 'echarts/i18n/langES';
import { forwardRef, useEffect, useImperativeHandle, useRef } from 'react';
import { EChartsOptionBuilder } from '..';

interface Props {
  option: echarts.EChartsOption | EChartsOptionBuilder;
  renderer?: 'canvas' | 'svg';
}

const ECharts = forwardRef<echarts.ECharts | null, Props>(({ option, renderer = 'svg' }, ref) => {
  const { i18n } = useLingui();
  const formatters = useFormatters();
  const refDivChart = useRef<HTMLDivElement>(null);
  const refChart = useRef<echarts.ECharts | null>(null);

  useImperativeHandle(ref, () => refChart.current!);

  useEffect(() => {
    const element = refDivChart.current;
    if (!element) {
      return;
    }
    const chartInstance = echarts.init(refDivChart.current, null, {
      renderer,
      locale: i18n.locale,
    });
    refChart.current = chartInstance;

    const resizeObserver = new ResizeObserver(() => {
      refChart?.current?.resize();
    });

    resizeObserver.observe(element);
    return () => {
      chartInstance.dispose();
      resizeObserver.unobserve(element);
    };
  }, []);

  useEffect(() => {
    if (!refDivChart.current || !refChart.current) return;

    const chartInstance = refChart.current;

    if (option instanceof EChartsOptionBuilder) {
      option.setChartInstance(chartInstance);
      option.setFormatters(formatters);
      chartInstance.setOption(option.getOption(), {
        notMerge: true,
        replaceMerge: ['series', 'yAxis', 'xAxis', 'grid'],
      });
    } else {
      chartInstance.setOption(option, {
        replaceMerge: ['series', 'yAxis', 'xAxis', 'grid'],
      });
    }
  }, [renderer, option, formatters]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={refDivChart}
    />
  );
});

export default ECharts;
