import { useDispatch } from '@/store';
import { setModalDisplayedAnnouncement } from '@features/announcements/slices/announcementsSlice';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { Campaign, Warning } from '@mui/icons-material';
import { ReactElement } from 'react';
import { NotificationEntity } from '../graphql/queries/getNotifications';

export const getEntityAttributes = (
  entity: NotificationEntity,
  dispatch: ReturnType<typeof useDispatch>,
  i18n: I18n,
) => {
  const attributes = {
    title: '',
    subtitle: '',
    icon: null as ReactElement | null,
    clickHandler: null as (() => void) | null,
    tableLabel: '',
  };
  if (entity.__typename === 'Announcement') {
    attributes.title = entity.title;
    attributes.subtitle = entity.description;
    attributes.clickHandler = () => dispatch(setModalDisplayedAnnouncement(entity.id));
    attributes.tableLabel = t(i18n)`Anuncio ${entity.id} - ${entity.title}: ${entity.description}`;
    attributes.icon = <Campaign sx={{ fontSize: 13, lineHeight: 1 }} color="primary" />;
  }

  if (entity.__typename === 'AlertNotification') {
    attributes.icon = <Warning sx={{ fontSize: 13, lineHeight: 1 }} color="warning" />;
    attributes.title = t(i18n)`Alerta dispositivo ${entity.device.profile.name}`;
    attributes.subtitle = entity.message;
    attributes.tableLabel = t(
      i18n,
    )`Alerta ${entity.id} - dispositivo ${entity.device.profile.name}: ${entity.message}`;
  }

  return attributes;
};
