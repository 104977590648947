import { gql } from '@/__generated__';
import type { DevicesAnalysis_GetDevicesQuery } from '@/__generated__/graphql';

export type Device = DevicesAnalysis_GetDevicesQuery['devices'][number];
export type Gate = DevicesAnalysis_GetDevicesQuery['gates'][number];

const DEVICES_ANALYSIS_GET_DEVICES = gql(`#graphql
  query DevicesAnalysis_GetDevices(
    $input: DevicesInput,
    $gatesInput: GatesInput,
    $metricsInput: MetricsParametersInput,
    $flowUnit: MetricUnit,
    $levelUnit: MetricUnit,
    $connectionStateInput: MetricsParametersInput,
    $volumeHour: MetricsParametersInput,
    $volumeDay: MetricsParametersInput,
    $volumeMonth: MetricsParametersInput,
    $includeFlow: Boolean!,
    $includeFlowRaw: Boolean!,
    $includeLevel: Boolean!,
    $includeLevelRaw: Boolean!,
    $includeVelocity: Boolean!,
    $includeVelocityRaw: Boolean!,
    $includeAngle: Boolean!,
    $includePosition: Boolean!,
    $includeUpstreamLevel: Boolean!,
    $includeDownstreamLevel: Boolean!,
    $includeOriginalDistance: Boolean!,
    $includeSignalQuality: Boolean!,
    $includeConnectionState: Boolean!,
    $includeAccumulatedVolume: Boolean!,
    $includeVolumeHour: Boolean!,
    $includeVolumeDay: Boolean!,
    $includeVolumeMonth: Boolean!,
    $includeBatterySoc: Boolean!,
    $includeBatteryV: Boolean!,
    $includeChargeI: Boolean!,
    $includeChargeP: Boolean!,
    $includeSolarI: Boolean!,
    $includeSolarP: Boolean!,
    $includeSolarV: Boolean!,
    $includeFlowAlerts: Boolean!,
    $includeLevelAlerts: Boolean!,
    $includeDistributionAlerts: Boolean!,
    $includeRatingCurvesLimits: Boolean!,
    $includeEvents: Boolean!,
    # $includeDgaFlow: Boolean!,
    # $includeDgaLevel: Boolean!,
    # $includeDgaAccumulatedVol: Boolean!,
    # $dgaLevelUnit: LevelUnit,
    $includeSnowLevel: Boolean!,
    $includeSnowLevelRaw: Boolean!,
    $includeSnowLevelNonCalibrated: Boolean!,
    $includeSnowTemperature: Boolean!,
    $includeSnowTemperatureRaw: Boolean!,
    $includeSnowRadiation: Boolean!,
    $includeSnowRadiationRaw: Boolean!,
    $includeSnowWaterEquivalent: Boolean!,
    $includeSnowWaterEquivalentRaw: Boolean!,
    $includeSnowWeight: Boolean!,
    $includeSweNonCalibrated: Boolean!,
    $includeTemperature: Boolean!,
    $includeTemperatureRaw: Boolean!,
    $includeConductivity: Boolean!,
    $includeConductivityRaw: Boolean!,
    $includePh: Boolean!,
    $includePhRaw: Boolean!,
    $includeOrp: Boolean!,
    $includeOrpRaw: Boolean!,
    $includeDissolvedOxygen: Boolean!,
    $includeDissolvedOxygenRaw: Boolean!,
    $includeDissolvedOxygenSaturation: Boolean!,
    $includeDissolvedOxygenSaturationRaw: Boolean!,
    $includeTurbidityNtu: Boolean!,
    $includeTurbidityNtuRaw: Boolean!,
    $includeTurbidityFnu: Boolean!,
    $includeTurbidityFnuRaw: Boolean!,
    $includeAllocation: Boolean!,
    $includeStoredVolume: Boolean!,
  ) {
    devices(input: $input) {
      id
      profile {
        id
        name
        type
        typeVersion
        sensor
        source
        sourceInfo
        status
        componentID
        logicalDeviceID
        irrigation {
          id
          name
        }
        organization {
          id
          name
        }
        location {
          latitude
          longitude
        }
      }
      alerts {
        id
        basicConfig {
          id
          flow(unit: $flowUnit) @include(if: $includeFlowAlerts) {
            timestamp
            min
            max
            overflow
            precision
            unit
          }
          level(unit: $levelUnit) @include(if: $includeLevelAlerts) {
            timestamp
            min
            max
            overflow
            precision
            unit
          }
          lastFlow(unit: $flowUnit) @include(if: $includeFlowAlerts) {
            timestamp
            min
            max
            overflow
            precision
            unit
          }
          lastLevel(unit: $levelUnit) @include(if: $includeLevelAlerts) {
            timestamp
            min
            max
            overflow
            precision
            unit
          }
          distributionLimits(unit: $flowUnit) {
            maxFlow @include(if: $includeDistributionAlerts)
            precision
            unit
          }
        }
      }
      metrics(input: $metricsInput) {
        id
        availableFields
        flow(unit: $flowUnit) {
          data @include(if: $includeFlow)
          lastData
          rawData @include(if: $includeFlowRaw)
          unit
          precision
        }
        allocation {
          data @include(if: $includeAllocation)
          unit
          precision
        }
        level(unit: $levelUnit) {
          data @include(if: $includeLevel)
          lastData
          rawData @include(if: $includeLevelRaw)
          unit
          precision
        }
        position {
          data @include(if: $includePosition)
          lastData
          unit
          precision
        }
        angle {
          data @include(if: $includeAngle)
          unit
          precision
        }
        velocity {
          data @include(if: $includeVelocity)
          rawData @include(if: $includeVelocityRaw)
          unit
          precision
        }
        originalDistance(unit: $levelUnit) {
          data @include(if: $includeOriginalDistance)
          unit
          precision
        }
        signalQuality {
          data @include(if: $includeSignalQuality)
          unit
          precision
        }
        connectionState(input: $connectionStateInput) {
          data @include(if: $includeConnectionState)
          unit
          precision
        }
        batterySoc {
          data @include(if: $includeBatterySoc)
          unit
          precision
        }
        batteryV {
          data @include(if: $includeBatteryV)
          unit
          precision
        }
        chargeI {
          data @include(if: $includeChargeI)
          unit
          precision
        }
        chargeP {
          data @include(if: $includeChargeP)
          unit
          precision
        }
        solarI {
          data @include(if: $includeSolarI)
          unit
          precision
        }
        solarP {
          data @include(if: $includeSolarP)
          unit
          precision
        }
        solarV {
          data @include(if: $includeSolarV)
          unit
          precision
        }
        temperature {
          data @include(if: $includeTemperature)
          rawData @include(if: $includeTemperatureRaw)
          unit
          precision
        }
        conductivity {
          data @include(if: $includeConductivity)
          rawData @include(if: $includeConductivityRaw)
          unit
          precision
        }
        ph {
          data @include(if: $includePh)
          rawData @include(if: $includePhRaw)
          unit
          precision
        }
        oxidationReductionPotential {
          data @include(if: $includeOrp)
          rawData @include(if: $includeOrpRaw)
          unit
          precision
        }
        dissolvedOxygen {
          data @include(if: $includeDissolvedOxygen)
          rawData @include(if: $includeDissolvedOxygenRaw)
          unit
          precision
        }
        dissolvedOxygenSaturation {
          data @include(if: $includeDissolvedOxygenSaturation)
          rawData @include(if: $includeDissolvedOxygenSaturationRaw)
          unit
          precision
        }
        turbidityNTU {
          data @include(if: $includeTurbidityNtu)
          rawData @include(if: $includeTurbidityNtuRaw)
          unit
          precision
        }
        turbidityFNU {
          data @include(if: $includeTurbidityFnu)
          rawData @include(if: $includeTurbidityFnuRaw)
          unit
          precision
        }
        snowLevel {
          data @include(if: $includeSnowLevel)
          lastData
          rawData @include(if: $includeSnowLevelRaw)
          unit
          precision
        }
        snowLevelNonCalibrated {
          data @include(if: $includeSnowLevelNonCalibrated)
          unit
          precision
        }
        snowTemperature {
          data @include(if: $includeSnowTemperature)
          rawData @include(if: $includeSnowTemperatureRaw)
          unit
          precision
        }
        snowRadiation {
          data @include(if: $includeSnowRadiation)
          rawData @include(if: $includeSnowRadiationRaw)
          unit
          precision
        }
        snowWaterEquivalent {
          data @include(if: $includeSnowWaterEquivalent)
          rawData @include(if: $includeSnowWaterEquivalentRaw)
          unit
          precision
        }
        snowWaterEquivalentNonCalibrated {
          data @include(if: $includeSweNonCalibrated)
          unit
          precision
        }
        snowWeight {
          data @include(if: $includeSnowWeight)
          unit
          precision
        }
        volumeHour: calculatedVolume(input: $volumeHour) {
          data @include(if: $includeVolumeHour)
          unit
          precision
        }
        volumeDay: calculatedVolume(input: $volumeDay) {
          data @include(if: $includeVolumeDay)
          unit
          precision
        }
        volumeMonth: calculatedVolume(input: $volumeMonth) {
          data @include(if: $includeVolumeMonth)
          unit
          precision
        }
        accumulatedVolume {
          data @include(if: $includeAccumulatedVolume)
          unit
          precision
        }
        storedVolume {
          data @include(if: $includeStoredVolume)
          lastData
          unit
          precision
        }
        events {
          data @include(if: $includeEvents) {
            timestamp
            value
            source
            userId
          }
        }
      }
      dataConfiguration @include(if: $includeRatingCurvesLimits) {
        deviceId
        ratingCurves {
          limits {
            min
            max
          }
        }
      }
    }
    gates: gates2(input: $gatesInput) {
      id
      downstreamDevice {
        id
        metrics(input: $metricsInput) {
          level(unit: $levelUnit) {
            data @include(if: $includeDownstreamLevel)
            unit
            precision
          }
        }
      }
      upstreamDevice {
        id
        metrics(input: $metricsInput) {
          level(unit: $levelUnit) {
            data @include(if: $includeUpstreamLevel)
            unit
            precision
          }
        }
      }
      hasAlarm
      alarmData
    }
  }
`);

export default DEVICES_ANALYSIS_GET_DEVICES;
