import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { amber, green } from '@mui/material/colors';
import { getNextExecutionDateMessage } from './getNextExecutionDateMessage';
import { defaultTooltip } from '@features/echarts/utils/baseOption';
import {
  DgaReportsTableModal_GetReportsQuery,
  DgaType,
  MetricUnit,
} from 'src/__generated__/graphql';
import { Formatters } from 'src/hooks/useFormatters';
import dayjs from 'dayjs';

type DeviceDGA = DgaReportsTableModal_GetReportsQuery['device']['dga'];
type Report = NonNullable<DeviceDGA>['reports']['items'][0];

const getTooltipFormatter =
  (formatters: Formatters, i18n: I18n, dgaType: DgaType) => (params: any) => {
    const { value } = params as {
      value: [number, number, string];
      marker: string;
    };
    const report: Report = JSON.parse(value[2]);
    const { data: metrics, message: errorMessage, executionTimestamp, success, timestamp } = report;
    const nextExecutionTimestamp = getNextExecutionDateMessage(executionTimestamp);
    const { flow, level, accumulatedVolume } = metrics;
    const category = success ? t(i18n)`Publicado` : t(i18n)`En reintento`;
    const date = formatters.formatDateTime(timestamp);
    const executionDate = formatters.formatDateTime(executionTimestamp);
    return `${params.marker} ${date}: <b>${category}</b><br />
    Fecha de ejecución: ${executionDate}<br />
${!success ? `${nextExecutionTimestamp}` : ''}
${errorMessage ? `<b style="text-wrap: wrap">${errorMessage}</b><br />` : ''}
${
  flow != null
    ? `Caudal: <b>${formatters.formatMetricValue(flow, {
        unit: MetricUnit.LiterPerSecond,
        precision: 0,
      })}</b><br />`
    : ''
}
${
  level != null
    ? `Nivel: <b>${formatters.formatMetricValue(level, {
        unit: dgaType === DgaType.Underground ? MetricUnit.Meter : MetricUnit.Centimeter,
        precision: 0,
      })}</b><br />`
    : ''
}
${
  accumulatedVolume != null
    ? `Volumen acumulado: <b>${formatters.formatMetricValue(accumulatedVolume, {
        unit: MetricUnit.CubicMeter,
        precision: 0,
      })}</b><br />`
    : ''
}`;
  };

export const getHourlyChartOption = (
  reports: Report[],
  startTs: number,
  dgaType: DgaType,
  formatters: Formatters,
  i18n: I18n,
) => {
  const statusToCategory = {
    0: t(i18n)`Publicado`,
    1: t(i18n)`En reintento`,
  };

  const formatWeekday = (timestamp: number) => {
    return formatters.formatDate(timestamp, {
      weekday: 'long',
      day: '2-digit',
    });
  };

  const formatHour = (hour: number) => {
    const date = new Date();
    date.setHours(hour, 0, 0, 0);
    return formatters.formatDateTime(date.getTime(), {
      hour: '2-digit',
      minute: '2-digit',
    });
  };


  const dateCategories = Array.from({ length: 7 }, (_, i) =>
    dayjs.unix(startTs).add(i, 'day').startOf('day').valueOf(),
  );

  const hourCategories = Array.from({ length: 24 }, (_, i) => i);
  const chartData: [number, number, string, number][] = [];

  reports.forEach((report) => {
    const { timestamp } = report;
    const date = dayjs(timestamp).startOf('day').valueOf();
    const dateIndex = dateCategories.indexOf(date);
    const hourIndex = hourCategories.indexOf(dayjs(timestamp).hour());
    if (dateIndex !== -1 && hourIndex !== -1) {
      chartData.push([dateIndex, hourIndex, JSON.stringify(report), report.success ? 1 : 0]);
    }
  });

  const option: echarts.EChartsOption = {
    grid: {
      top: 40,
      left: 0,
      right: 0,
      bottom: 10,
      containLabel: true,
    },
    legend: {
      show: false,
    },
    tooltip: {
      ...defaultTooltip,
      trigger: 'item',
      position: 'top',
      formatter: getTooltipFormatter(formatters, i18n, dgaType),
    },
    visualMap: {
      top: 0,
      orient: 'horizontal',
      textStyle: {
        color: 'white',
      },
      type: 'piecewise',
      categories: Object.values(statusToCategory),
      dimension: 3,
      pieces: [
        {
          value: 1,
          label: statusToCategory[0],
          color: green[700],
        },
        {
          value: 0,
          label: statusToCategory[1],
          color: amber[700],
        },
      ],
    },
    xAxis: {
      id: 'day',
      type: 'category',
      data: dateCategories,
      splitArea: {
        show: true,
      },
      axisLabel: {
        formatter(value) {
          return formatWeekday(value);
        },
      },
    },
    yAxis: {
      id: 'hour',
      type: 'category',
      data: hourCategories,
      splitArea: {
        show: true,
      },
      axisLabel: {
        formatter(value) {
          return formatHour(value);
        },
      },
    },
    series: [
      {
        xAxisId: 'day',
        yAxisId: 'hour',
        type: 'heatmap',
        data: chartData,
        encode: {
          x: 0,
          y: 1,
          value: 3,
        },
        itemStyle: {
          borderWidth: 1,
          borderColor: 'rgba(0, 0, 0, 0.2)',
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 4,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
          },
        },
      },
    ],
  };

  return option;
};

export const getCalendarChartOption = (
  reports: Report[],
  dgaType: DgaType,
  formatters: Formatters,
  i18n: I18n,
  months: 3 | 12 | 24 = 3,
  orient: 'horizontal' | 'vertical' = 'horizontal',
) => {
  const groups = months === 3 ? 1 : months === 12 ? 3 : 6;
  const deltaMonths = months / groups;

  const intervals = Array.from({ length: groups }, (_, i) => {
    const endDate = new Date();
    endDate.setMonth(endDate.getMonth() + 1 - i * deltaMonths, 0);
    const startDate = new Date(endDate);
    startDate.setMonth(startDate.getMonth() - deltaMonths + 1, 1);

    const days = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24);
    const scatterData = Array.from({ length: days + 2 }, (_, i) => {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i);
      const dayLabel = formatters.formatDate(date, {
        day: '2-digit',
      });
      return [date.getTime(), dayLabel];
    });

    const heatmapData = reports.map((report) => {
      const { timestamp, success } = report;
      if (timestamp >= startDate.getTime() && timestamp <= endDate.getTime())
        return [timestamp, success ? 1 : 0, JSON.stringify(report)];
      else return [];
    });

    return [startDate.getTime(), endDate.getTime(), scatterData, heatmapData] as const;
  }).reverse();

  const option: echarts.EChartsOption = {
    grid: {},
    tooltip: {
      ...defaultTooltip,
      trigger: 'item',
      formatter: getTooltipFormatter(formatters, i18n, dgaType),
    },
    legend: {
      show: false,
    },
    visualMap: {
      top: 0,
      orient: 'horizontal',
      textStyle: {
        color: 'white',
      },
      type: 'piecewise',
      dimension: 1,
      pieces: [
        {
          value: 1,
          label: t(i18n)`Publicado`,
          color: green[700],
        },
        {
          value: 0,
          label: t(i18n)`En reintento`,
          color: amber[700],
        },
      ],
    },
    calendar: intervals.map(([startDate, endDate], i) => ({
      orient,
      top: orient === 'vertical' ? 90 : 60,
      left: `${10 + i * 30}%`,
      width: `${90 / groups - 8}%`,
      bottom: 10,
      range: [startDate, endDate],
      dayLabel: {
        color: 'white',
        show: true,
        firstDay: 1,
        margin: 10,
      },
      monthLabel: {
        show: true,
        color: 'white',
        margin: 10,
      },
      yearLabel: {
        show: true,
        margin: 30,
      },
      itemStyle: {
        color: '#303b49',
        borderColor: 'rgba(0, 0, 0, 0.2)',
      },
      splitLine: {
        show: true,
        lineStyle: {
          width: 2,
          color: 'rgba(0, 0, 0, 0.4)',
          type: 'solid',
        },
      },
    })),
    series: intervals.flatMap(([_, __, scatterData, heatmapData], index) => [
      {
        type: 'scatter',
        coordinateSystem: 'calendar',
        calendarIndex: index,
        symbolSize: 0,
        label: {
          show: true,
          color: 'rgba(255, 255, 255, 0.8)',
          fontSize: 9,
        },
        data: scatterData,
        silent: true,
      },
      {
        type: 'heatmap',
        coordinateSystem: 'calendar',
        data: heatmapData,
        calendarIndex: index,
      },
    ]),
  };
  return option;
};
