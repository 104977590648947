// mui components
import { Autocomplete, TextField } from '@mui/material';

// react hook form
import { Control, Controller } from 'react-hook-form';
import { FormValues } from '../type/FormValues';

import { SelectorOption } from "src/__generated__/graphql";

interface Props {
  options: SelectorOption[];
  label: string;
  name: string;
  control: Control<FormValues>;
}

const OptionSelector = ({ options, label, name, control}: Props) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          id="icn-filter"
          options={options}
          getOptionLabel={(option) => option.name}
          isOptionEqualToValue={(option, value) =>
            option.id == value.id
          }
          value={value}
          onChange={(_, newValue) => {
            onChange(newValue ?? null);
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              helperText=""
              style={{ width: '100%' }}
            />
          )}
        />
      )}
    />
  );
};

export default OptionSelector;
