import { defaultTooltip } from '@features/echarts/utils/baseOption';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';
import { amber, green } from '@mui/material/colors';
import dayjs from 'dayjs';
import { SmaReports } from 'src/__generated__/graphql';
import { Formatters } from 'src/hooks/useFormatters';

type ChartPoint = [number, number, number, number];

const getTooltipFormatter = (formatters: Formatters, i18n: I18n) => (params: any) => {
  const { data, marker } = params as { data: ChartPoint; marker: string };
  const dateString = formatters.formatDateTime(dayjs.unix(data[2]).toDate());
  if (data[3] === 1) {
    return t(i18n)`${marker} ${dateString}: <b>Publicado</>`;
  }
  return t(i18n)`${marker} ${dateString}: <b>En reintento</b>`;
};

export const getHourlyChartOption = (
  reportData: SmaReports[],
  startTs: number,
  formatters: Formatters,
  i18n: I18n,
) => {
  const statusToCategory = {
    0: t(i18n)`Publicado`,
    1: t(i18n)`En reintento`,
  };

  const formatWeekday = (timestamp: number) => {
    return formatters.formatDate(timestamp * 1000, {
      weekday: 'long',
      day: '2-digit',
    });
  };

  const formatHour = (hour: number) => {
    const date = new Date();
    date.setHours(hour, 0, 0, 0);
    return formatters.formatDateTime(date.getTime(), {
      hour: '2-digit',
      minute: '2-digit',
    });
  };

  const dateCategories = Array.from({ length: 7 }, (_, i) =>
    dayjs.unix(startTs).add(i, 'day').startOf('hour').unix(),
  );

  const hourCategories = Array.from({ length: 24 }, (_, i) => i);
  const chartData: ChartPoint[] = [];

  reportData.forEach((data) => {
    const { timestamp, success } = data;
    const dateTs = dayjs.unix(timestamp).hour(0).unix();
    const dateIndex = dateCategories.indexOf(dateTs);
    const hourIndex = dayjs.unix(timestamp).hour();
    if (dateIndex !== -1 && hourIndex !== -1) {
      chartData.push([dateIndex, hourIndex, timestamp, success ? 1 : 0]);
    }
  });

  const option: echarts.EChartsOption = {
    grid: {
      top: 40,
      left: 0,
      right: 0,
      bottom: 10,
      containLabel: true,
    },
    legend: {
      show: false,
    },
    tooltip: {
      ...defaultTooltip,
      trigger: 'item',
      position: 'top',
      formatter: getTooltipFormatter(formatters, i18n),
    },
    visualMap: {
      top: 0,
      orient: 'horizontal',
      textStyle: {
        color: 'white',
      },
      type: 'piecewise',
      categories: Object.values(statusToCategory),
      dimension: 3,
      pieces: [
        {
          value: 1,
          label: statusToCategory[0],
          color: green[700],
        },
        {
          value: 0,
          label: statusToCategory[1],
          color: amber[700],
        },
      ],
    },
    xAxis: {
      id: 'day',
      type: 'category',
      data: dateCategories,
      splitArea: {
        show: true,
      },
      axisLabel: {
        formatter(value) {
          return formatWeekday(parseInt(value));
        },
      },
    },
    yAxis: {
      id: 'hour',
      type: 'category',
      data: hourCategories,
      splitArea: {
        show: true,
      },
      axisLabel: {
        formatter(value) {
          return formatHour(parseInt(value));
        },
      },
    },
    series: [
      {
        xAxisId: 'day',
        yAxisId: 'hour',
        type: 'heatmap',
        data: chartData,
        encode: {
          x: 0,
          y: 1,
          value: 3,
        },
        itemStyle: {
          borderWidth: 1,
          borderColor: 'rgba(0, 0, 0, 0.2)',
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 4,
            shadowColor: 'rgba(0, 0, 0, 0.3)',
          },
        },
      },
    ],
  };

  return option;
};
