// mui components
import { Checkbox, Typography, Grid } from '@mui/material';

// use form hook
import { Controller } from 'react-hook-form';

export const FormCheckbox = ({ name, control, label }) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography color="textSecondary">{label}</Typography>
        <Controller
          name={name}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Checkbox
              checked={value}
              onChange={(e) => onChange(e.target.checked)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};
