// apollo client
import { gql } from '@/__generated__';

const FETCH_HARDWARE_OPTIONS = gql(`
	query FetchHardwareOptions {
		hardwareOptions {
			id
			name
			type: type
		}
	}
`);


export {
	FETCH_HARDWARE_OPTIONS,
};
