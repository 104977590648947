export enum Access {
  // test
  _Test_True,
  _Test_False,
  _Test_Random,

  // components
  OrganizationsFilter,
  AlertsConfiguration_TableRowAdminView,

  // modals
  CreateDGAConfigurationModal,
  DeviceConfigurationModal,
  DeviceDataConfigurationModal,
  DeviceAlertsConfigurationModal,
  DeleteDeviceModal,
  CloneDeviceModal,
  SaveUserModal,
  SimulateUserModal,
  DeleteUserModal,
  CreateTemporalUserModal,
  SaveHardwareConfigurationModal,

  // buttons
  UsersTable_OptionsMenu,
  UserOptionsMenu_SimulateUserButton,
  UserOptionsMenu_DeleteUserButton,
}
