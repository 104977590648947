import { gql } from '@/__generated__';

const NOTIFICATION_DELETED = gql(`#graphql
  subscription NotificationDeleted {
    notificationDeleted {
      notificationId
      notificationObjectId
      recipientId
    }
  }
`);

export default NOTIFICATION_DELETED;
