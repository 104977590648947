import { t } from '@lingui/macro';
import { Device } from '../graphql/queries/getDeviceProfile';

/**
 * @returns 1 if the value is not empty, 0 if the value is empty
 * @description Validate if the field is required
 */
const required = (value: string) => {
  return value ? 1 : 0;
};

export const getSectionInputs = () => ({
  general: {
    inputs: [
      {
        name: 'hardwareConfiguration.googleDriveUrl',
        label: t`URL Google Drive`,
      },
      {
        name: 'profile.project',
        label: t`Nombre del proyecto`,
        validator: required,
      },
      {
        name: 'hardwareConfiguration.lockNumber',
        label: t`Número de chapa`,
      },
      {
        name: 'profile.dateAction',
        label: t`Fecha de activación`,
        validator: required,
      },
      {
        name: 'hardwareConfiguration.observation',
        label: t`Observación`,
      },
    ] as const,
    label: t`General`,
  },
  sensorization: {
    inputs: [
      {
        name: 'hardwareConfiguration.sensorLevelOption',
        label: t`Sensor de nivel`,
        validator: (value: string, device: Device) => {
          return device.deviceTypeMeasurement?.level ? (value === '' || value == null ? 0 : 1) : -1;
        },
      },
      {
        name: 'hardwareConfiguration.serialNumberLevel',
        label: t`Número de serie`,
        validator: (value: string, device: Device) => {
          return device.deviceTypeMeasurement?.level ? (value === '' || value == null ? 0 : 1) : -1;
        },
      },
      {
        name: 'hardwareConfiguration.batchNumberLevelSensor',
        label: t`Número de lote`,
        validator: (value: string, device: Device) => {
          return device.deviceTypeMeasurement?.level ? (value === '' || value == null ? 0 : 1) : -1;
        },
      },
      {
        name: 'hardwareConfiguration.sensorVelocityOption',
        label: t`Sensor de velocidad`,
        validator: (value: string, device: Device) => {
          return device.deviceTypeMeasurement?.velocity
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.serialNumberVelocity',
        label: t`Número de serie`,
        validator: (value: string, device: Device) => {
          return device.deviceTypeMeasurement?.velocity
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.batchNumberVelocitySensor',
        label: t`Número de lote`,
        validator: (value: string, device: Device) => {
          return device.deviceTypeMeasurement?.velocity
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.sensorFlowOption',
        label: t`Sensor de flujo`,
        validator: (value: string, device: Device) => {
          return device.deviceTypeMeasurement?.flow ? (value === '' || value == null ? 0 : 1) : -1;
        },
      },
      {
        name: 'hardwareConfiguration.serialNumberFlow',
        label: t`Número de serie`,
        validator: (value: string, device: Device) => {
          return device.deviceTypeMeasurement?.flow ? (value === '' || value == null ? 0 : 1) : -1;
        },
      },
      {
        name: 'hardwareConfiguration.batchNumberFlowSensor',
        label: t`Número de lote`,
        validator: (value: string, device: Device) => {
          return device.deviceTypeMeasurement?.flow ? (value === '' || value == null ? 0 : 1) : -1;
        },
      },
    ] as const,
    label: t`Sensorización`,
  },
  energization: {
    inputs: [
      {
        name: 'hardwareConfiguration.energizationOption',
        label: t`Energización`,
        validator: required,
      },
      {
        name: 'hardwareConfiguration.solarPanelsControllers',
        label: t`Paneles solares`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName === 'Solar' || energizationName === 'Red 220VAC y Solar'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.numberSolarPanelsControllers',
        label: t`Número de serie`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName === 'Solar' || energizationName === 'Red 220VAC y Solar'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.batteryCapacityOption',
        label: t`Batería`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName === 'Solar' ||
            energizationName === 'Red 220VAC y Solar' ||
            energizationName === 'Red 220VAC (UPS)'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.quantityBattery',
        label: t`Cantidad de baterías`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName === 'Solar' ||
            energizationName === 'Red 220VAC y Solar' ||
            energizationName === 'Red 220VAC (UPS)'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.modelBattery',
        label: t`Modelo de batería`,
      },
      {
        name: 'hardwareConfiguration.technologyOption',
        label: t`Tecnología`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName === 'Solar' ||
            energizationName === 'Red 220VAC y Solar' ||
            energizationName === 'Red 220VAC (UPS)'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.batteryVoltageOption',
        label: t`Voltaje`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName === 'Solar' ||
            energizationName === 'Red 220VAC y Solar' ||
            energizationName === 'Red 220VAC (UPS)'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.quantitySun',
        label: t`Cantidad de paneles solares`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName === 'Solar' || energizationName === 'Red 220VAC y Solar'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.modelsSun',
        label: t`Modelo de paneles solares`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName === 'Solar' || energizationName === 'Red 220VAC y Solar'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.watts',
        label: t`Watts`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName === 'Solar' || energizationName === 'Red 220VAC y Solar'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.numberSun',
        label: t`Número de serie`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName === 'Solar' || energizationName === 'Red 220VAC y Solar'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.powerSupplyVoltageOption',
        label: t`Voltaje de alimentación`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName !== 'Solar' ? (value === '' || value == null ? 0 : 1) : -1;
        },
      },
      {
        name: 'hardwareConfiguration.powerSupplyModel',
        label: t`Modelo de alimentación`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName !== 'Solar' ? (value === '' || value == null ? 0 : 1) : -1;
        },
      },
      {
        name: 'hardwareConfiguration.powerSupplySerialNumber',
        label: t`Número de serie de alimentación`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName !== 'Solar' ? (value === '' || value == null ? 0 : 1) : -1;
        },
      },
      {
        name: 'hardwareConfiguration.powerSupplyBrand',
        label: t`Marca de alimentación`,
        validator: (value: string, device: Device) => {
          const energizationName = device?.hardwareConfiguration?.energizationOption?.name;
          return energizationName !== 'Solar' ? (value === '' || value == null ? 0 : 1) : -1;
        },
      },
    ] as const,
    label: t`Energización`,
  },
  processor: {
    inputs: [
      {
        name: 'hardwareConfiguration.cpuOption',
        label: t`CPU`,
        validator: required,
      },
      {
        name: 'hardwareConfiguration.batchNumberCpu',
        label: t`Número de lote CPU`,
        validator: required,
      },
      {
        name: 'hardwareConfiguration.auxiliaryOption',
        label: t`Placa auxiliar`,
        validator: required,
      },
      {
        name: 'hardwareConfiguration.numberAuxiliary',
        label: t`Número de serie`,
        validator: required,
      },
      {
        name: 'hardwareConfiguration.batchNumberAuxiliary',
        label: t`Número de lote placa`,
        validator: required,
      },
      {
        name: 'hardwareConfiguration.imageVersion',
        label: t`Versión de imagen`,
        validator: required,
      },
      {
        name: 'hardwareConfiguration.usbExtraction',
        label: t`Extracción USB`,
      },
    ] as const,
    label: t`Data logger`,
  },
  connectivity: {
    inputs: [
      {
        name: 'hardwareConfiguration.internetConnectionOption',
        label: t`Conexión a internet`,
        validator: required,
      },
      {
        name: 'hardwareConfiguration.modelsConnectionOption',
        label: t`Modelo de conexión`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          return internetConnectionName === 'Redes Móviles'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.imei',
        label: t`hardwareConfiguration.IMEI`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          return internetConnectionName === 'Redes Móviles' ||
            internetConnectionName === 'Internet Satelital'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.batchNumberSimCard',
        label: t`Número de lote SIM`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          return internetConnectionName === 'Redes Móviles'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.approved',
        label: t`Homologado`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          return internetConnectionName === 'Redes Móviles'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.mobileOperatorOption',
        label: t`Operador móvil`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          return internetConnectionName === 'Redes Móviles'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.idEmnify',
        label: t`ID Emnify`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          const mobileOperatorName = device?.hardwareConfiguration?.mobileOperatorOption?.name;
          return internetConnectionName === 'Redes Móviles' && mobileOperatorName === 'Emnify'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.antennaOption',
        label: t`Antena`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          return internetConnectionName === 'Redes Móviles'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.iccid',
        label: t`ICCID`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          const mobileOperatorName = device?.hardwareConfiguration?.mobileOperatorOption?.name;
          return (internetConnectionName === 'Redes Móviles' && mobileOperatorName === 'Emnify') ||
            internetConnectionName === 'Internet Satelital'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.ip',
        label: t`IP`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          const mobileOperatorName = device?.hardwareConfiguration?.mobileOperatorOption?.name;
          return internetConnectionName === 'Redes Móviles' && mobileOperatorName === 'Emnify'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.mac',
        label: t`MAC`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          return internetConnectionName === 'Red Cableada' ||
            internetConnectionName === 'Red Inalámbrica'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.configurationIp',
        label: t`Configuración IP`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          return internetConnectionName === 'Red Cableada' ||
            internetConnectionName === 'Red Inalámbrica'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.administrator',
        label: t`Administrador`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          return internetConnectionName === 'Red Cableada' ||
            internetConnectionName === 'Red Inalámbrica'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.networkName',
        label: t`Nombre de red`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          return internetConnectionName === 'Red Inalámbrica'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
      {
        name: 'hardwareConfiguration.satellitalTypeOption',
        label: t`Tipo Satelital`,
        validator: (value: string, device: Device) => {
          const internetConnectionName =
            device?.hardwareConfiguration?.internetConnectionOption?.name;
          return internetConnectionName === 'Internet Satelital'
            ? value === '' || value == null
              ? 0
              : 1
            : -1;
        },
      },
    ] as const,
    label: t`Conectividad`,
  },
});
