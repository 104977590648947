import { Box, DialogTitle, Typography } from '@mui/material';
import { formatSourceColor } from 'src/utils/devices';
import { Device } from 'src/__generated__/graphql';

const HeaderConfigDevice = (props) => {
  const device: Device  = props.device
  const typeColor = formatSourceColor(device?.profile);
  const dateString = new Date(device?.profile?.created).toLocaleDateString();
  return (
    <DialogTitle sx={{ backgroundColor: '#2a3542' }}>
      <Box sx={{ display: 'flex' }}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <Typography variant="h5">{device?.profile?.name}</Typography>
          <Typography>-</Typography>
          <Typography>
            ID <b>{device?.id}</b>
          </Typography>
          <Typography>-</Typography>
          <Typography color={typeColor}>
            <b>{device?.profile?.type}</b>
          </Typography>
        </Box>
        <Box>
          <Typography align="right">
            Organización: <b>{device?.profile.organization?.name}</b>
          </Typography>
          <Typography align="right">
            Creación: <b>{dateString}</b>
          </Typography>
        </Box>
      </Box>
    </DialogTitle>
  );
};

export default HeaderConfigDevice;
