import { get } from '@/utils/get';
import { useSuspenseQuery } from '@apollo/client';
import { getSectionInputs } from '@features/deviceConfiguration/data/sectionInputs';
import GET_DEVICE_HARDWARE_CONFIGURATION from '@features/deviceConfiguration/graphql/queries/getDeviceProfile';
import { Engineering, Sensors, Bolt, Memory, SettingsInputAntenna } from '@mui/icons-material';

const sectionIcons = {
  general: Engineering,
  sensorization: Sensors,
  energization: Bolt,
  processor: Memory,
  connectivity: SettingsInputAntenna,
};

export enum HardwareConfigurationState {
  NoData,
  Incomplete,
  Complete,
}

export const useSuspenseHardwareConfigurationState = (deviceId: string) => {
  const { data } = useSuspenseQuery(GET_DEVICE_HARDWARE_CONFIGURATION, {
    variables: {
      deviceId,
    },
  });
  const sectionInputs = getSectionInputs();
  const device = data.device;

  const getSectionState = (section: keyof typeof sectionInputs) => {
    if (!device.hardwareConfiguration) return HardwareConfigurationState.NoData;

    if (section === 'sensorization' && device.profile.type.includes('LG') && device.sensors) {
      const fields = device.sensors.flatMap((sensor) => {
        const { sensorModelOption, serialNumber, batchNumber, modbusId } =
          sensor?.configuration || {};
        return [sensorModelOption, serialNumber, batchNumber, modbusId];
      });
      if (!fields.some((f) => f)) return HardwareConfigurationState.NoData;
      if (fields.every((f) => f)) return HardwareConfigurationState.Complete;
      return HardwareConfigurationState.Incomplete;
    }

    const { inputs } = sectionInputs[section];
    const validationArray = inputs.map((input) => {
      if ('validator' in input && input.validator != null) {
        const value = get(device, input.name);
        return input.validator(value as string, device);
      } else return -1;
    });
    if (!validationArray.includes(0)) return HardwareConfigurationState.Complete;
    if (validationArray.includes(1)) return HardwareConfigurationState.Incomplete;
    return HardwareConfigurationState.NoData;
  };

  return (Object.keys(sectionInputs) as Array<keyof typeof sectionInputs>).map((section) => {
    const state = getSectionState(section);
    let color = '#B61F3C';
    if (state === HardwareConfigurationState.Complete) color = '#6FBCB3';
    if (state === HardwareConfigurationState.Incomplete) color = '#FF9E42';
    return {
      sectionLabel: sectionInputs[section].label,
      section,
      state,
      Icon: sectionIcons[section],
      color,
    };
  });
};
