import { useFormatters } from '@/hooks/useFormatters';
import { getDGAFrequencyTranslation } from '@/utils/i18n/getDgaTranslations';
import { useSuspenseQuery } from '@apollo/client';
import { useLingui } from '@lingui/react';
import GET_DGA_CONFIGURATION_WITH_SUBMISSIONS from '../graphql/queries/getDGAConfigurationWithSubmissions';

export enum DgaConfigurationState {
  NoData,
  Incomplete,
  CompleteWithoutSubmissions,
  CompleteWithSubmissions,
}

export const useSuspenseDgaConfigurationState = (deviceId: string) => {
  const { formatDateTime } = useFormatters();
  const { i18n } = useLingui();
  const { data } = useSuspenseQuery(GET_DGA_CONFIGURATION_WITH_SUBMISSIONS, {
    variables: {
      deviceId,
    },
  });
  const { dgaConfiguration } = data.device;

  const { rut, code, password, type, enabled, frequency, hour, weekday, day } =
    dgaConfiguration ?? {};

  const configurationFields = [rut, code, password, type];

  let state = DgaConfigurationState.NoData;
  if (configurationFields.every((f) => f)) {
    if (enabled) state = DgaConfigurationState.CompleteWithSubmissions;
    else state = DgaConfigurationState.CompleteWithoutSubmissions;
  } else if (configurationFields.some((f) => f)) {
    state = DgaConfigurationState.Incomplete;
  }

  return {
    state,
    frequencyLabel:
      frequency && enabled
        ? getDGAFrequencyTranslation(
            frequency,
            {
              hour,
              weekday,
              day,
            },
            i18n,
            formatDateTime,
          )
        : null,
  };
};
