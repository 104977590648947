import { gql } from '@/__generated__';
import { UserList_GetUsersQuery } from '@/__generated__/graphql';

export type UserList_User = UserList_GetUsersQuery['usersPagination']['items'][number];

const GET_USER_LIST = gql(`#graphql
  query UserList_GetUsers($input: UsersInput, $pagination: PaginationInput, $sort: SortInput) {
    usersPagination(input: $input, pagination: $pagination, sort: $sort) {
      items {
        id
        fullName
        email
        lastAccess
        phone
        verifyAuth
        status
        mainOrganization {
          organization {
            id
            name
            verifyAuth
          }
          role {
            id
            name
          }
        }
        additionalOrganizations {
          organization {
            id
            name
          }
          role {
            id
            name
          }
        }
        temporaryUser {
          activationToken
          activationTokenExpiration
          temporaryUserExpiration
          temporaryUserDurationDays
        }
      }
      pageInfo {
        count
        limit
        offset
        pageNumber
        totalCount
      }
    }
  }

`);

export default GET_USER_LIST;
