import { gql } from '@/__generated__';
import { DeviceList_GetDevicesQuery } from '@/__generated__/graphql';

export type Device = DeviceList_GetDevicesQuery['devicesConnection']['nodes'][number];

export const DEVICE_LIST_DGA_INFO_FRAGMENT = gql(`#graphql
  fragment DeviceList_DGAInfo on Device {
    id
    dgaConfiguration {
      id
      type
      code
      enabled
      frequency
      hour
      weekday
      day
    }
    dgaTicket {
      id
      timestamp
    }
  }
`);

export const DEVICE_LIST_INFO_FRAGMENT = gql(`#graphql
  fragment DeviceList_Info on Device {
    id
    profile {
      id
      type
      name
      sensor
      source
      sourceInfo
      sensor
      status
      organization {
        id
        name
      }
      irrigation {
        id
        name
      }
    }
    hardwareConfiguration {
      usbExtraction
    }
    alerts {
      id
      basicConfig { 
        id
        lastFlow {
          timestamp
          min
          max
          overflow
          unit
          precision
        }
        lastLevel {
          timestamp
          min
          max
          overflow
          unit
          precision
        }
        distributionLimits {
          maxFlow
          unit
          precision
        }
      }
    }
    dataConfiguration {
      deviceId
      state {
        isDry
      }
    }
    metrics {
      id
      flow {
        lastData
      }
      level {
        lastData
      }
      velocity {
        lastData
      }
    }
  }
`);

export const DEVICE_LIST_METRICS_FRAGMENT = gql(`#graphql
  fragment DeviceList_Metrics on Device {
    id
    profile {
      id
      sensor
      typeVersion
    }
    metrics {
      id
      flow {
        lastData
        lastAlertState
        unit
        precision
      }
      level {
        lastData
        lastAlertState
        unit
        precision
      }
      velocity {
        lastData
        lastAlertState
        unit
        precision
      }
      accumulatedVolume {
        lastData
        lastAlertState
        unit
        precision
      }
      angle {
        lastData
        lastAlertState
        unit
        precision
      }
      storedVolume {
        lastData
        lastAlertState
        unit
        precision
      }
    }
  }
`);

export const DEVICE_LIST_MINICHARTS_FRAGMENT = gql(`#graphql
  fragment DeviceList_Minicharts on Device {
    chartMetrics: metrics(
      input: {
        top: 10,
        start: "-6h"
      }
    ) {
      id
      availableFields
      batterySoc {
        data
        unit
        precision
      }
      solarV {
        data
        unit
        precision
      }
    }
    groupedMetrics: metrics(
      input: {
        top: 10,
        start: "-6h"
      },
      groupOperation: {
        groupBy: DEVICE,
        topFill: 10,
      },
    ) {
      id
      flow {
        data
        unit
        precision
      }
      level {
        data
        unit
        precision
      }
    }
  }
`);

export const DEVICE_LIST_PICTURE_FRAGMENT = gql(`#graphql
  fragment DeviceList_Picture on Device {
    id
    regis {
      lastPhoto
    }
    profile {
      id
      name
    }
    metrics {
      id
      flow {
        lastData
        lastAlertState
        unit
        precision
      }
      level {
        lastData
        lastAlertState
        unit
        precision
      }
    }
  }
`);

export const DEVICE_LIST_SMA_INFO_FRAGMENT = gql(`#graphql
  fragment DeviceList_SMAInfo on Device {
    id
    smaConfig {
      id
      smaProcessId
      smaUnitId
      metricField
      smaDeviceId
      smaMetricName
      smaMetricUnit
      username
      password
      enabled
      schedule {
        frequency
        hour
        weekday
        day
      }
    }
  }
`);

const DEVICE_LIST_GET_DEVICES = gql(`#graphql
  query DeviceList_GetDevices(
    $dgaVariant: Boolean!,
    $smaVariant: Boolean!,
    $input: DevicesInput,
    $pagination: PaginationInput,
  ) {
    devicesConnection(input: $input, pagination: $pagination) {
      pageInfo {
        count
        totalCount
        pageNumber
        limit
        offset
      }
      nodes {
        id
        profile {
          id
          type
          status
          source
          sourceInfo
        }
        ...DeviceList_Info
        ...DeviceList_Metrics @skip(if: $dgaVariant)
        ...DeviceList_Minicharts @skip(if: $dgaVariant)
        ...DeviceList_Picture @skip(if: $dgaVariant)
        ...DeviceList_DGAInfo @include(if: $dgaVariant)
        ...DeviceList_SMAInfo @include(if: $smaVariant)
      }
    }
  }
`);

export default DEVICE_LIST_GET_DEVICES;
