import { useQuery } from '@apollo/client';
import { FETCH_HARDWARE_OPTIONS } from '../graphql/querys/fetchOptions';

const useHardwareOptions = (type) => {
  const { data = {}, loading, refetch } = useQuery(FETCH_HARDWARE_OPTIONS);
  const { hardwareOptions = null } = data;
  const options = type ? (hardwareOptions?.filter((option)=> option?.type === type) ?? []) : hardwareOptions ?? [];
  return { options, loading, refetch };
};

export default useHardwareOptions;