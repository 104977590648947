import { Grid, Typography, Checkbox } from '@mui/material';
import _ from 'lodash';

export const CheckoutData = ({ check, setCheck, title, name }) => {
  return (
    <Grid
      item 
      xs={12}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography color="textSecondary">{title}</Typography>
      <Checkbox
        checked={_.get(check, `${name}`)}
        onChange={() => {
          if (name === 'flow' && _.get(check, `${name}`)) {
            setCheck({
              ...check,
              [name]: !_.get(check, `${name}`),
              volume: false,
            });
          } else {
            setCheck({
              ...check,
              [name]: !_.get(check, `${name}`),
            });
          }
        }}
      />
    </Grid>
  );
};
