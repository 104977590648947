import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { type FC, useState } from 'react';
import { useSelector } from '@/store';
import { Accordion, AccordionDetails, AccordionSummary, SubAccordion } from './CustomAccordions';
import SidebarAdvancedCheckboxList from './SidebarAdvancedCheckboxList';
import SidebarAlertLevelsCheckboxList from './SidebarAlertLevelsCheckboxList';
import SidebarEnergyCheckboxList from './SidebarEnergyCheckboxList';
import SidebarTelemetryCheckboxList from './SidebarTelemetryCheckboxList';
import SidebarVolumeCheckboxList from './SidebarVolumeCheckboxList';
import SidebarPollingInterval from './SidebarPollingInterval';
import { MetricField } from '@/__generated__/graphql';
import { Trans } from '@lingui/macro';

export enum Expanded {
  None,
  Telemetry,
  Energy,
  Advanced,
}

enum ExpandedTelemetry {
  None,
  Volume,
  Alerts,
}

const Sidebar: FC = () => {
  const [expanded, setExpanded] = useState<Expanded>(Expanded.Telemetry);
  const [expandedTelemetry, setExpandedTelemetry] = useState<ExpandedTelemetry>(
    ExpandedTelemetry.None,
  );
  const { telemetry } = useSelector((state) => state.devices_analysis_store);

  const handleChangeExpanded =
    (panel: Expanded) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : Expanded.None);
    };

  const handleChangeExpandedTelemetry =
    (panel: ExpandedTelemetry) => (_: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedTelemetry(newExpanded ? panel : ExpandedTelemetry.None);
    };

  return (
    <Box>
      <Box mb={2}>
        <Accordion
          expanded={expanded === Expanded.Telemetry}
          onChange={handleChangeExpanded(Expanded.Telemetry)}
        >
          <AccordionSummary>
            <Trans comment="Análisis de estaciones - Título de acordión">Telemetría</Trans>
          </AccordionSummary>
          <AccordionDetails>
            <SidebarTelemetryCheckboxList />
            {telemetry.availableFields.some((field) =>
              [
                MetricField.StoredVolume,
                MetricField.AccumulatedVolume,
                MetricField.VolumeMonth,
                MetricField.VolumeDay,
                MetricField.VolumeHour,
              ].includes(field),
            ) && (
              <SubAccordion
                expanded={expandedTelemetry === ExpandedTelemetry.Volume}
                onChange={handleChangeExpandedTelemetry(ExpandedTelemetry.Volume)}
              >
                <AccordionSummary>
                  <Trans comment="Análisis de estaciones - Título de acordión">Volumen</Trans>
                </AccordionSummary>
                <AccordionDetails>
                  <SidebarVolumeCheckboxList />
                </AccordionDetails>
              </SubAccordion>
            )}
            {telemetry.availableFields.some((field) =>
              [
                MetricField.FlowAlerts,
                MetricField.LevelAlerts,
                MetricField.DistributionAlerts,
              ].includes(field),
            ) && (
              <SubAccordion
                expanded={expandedTelemetry === ExpandedTelemetry.Alerts}
                onChange={handleChangeExpandedTelemetry(ExpandedTelemetry.Alerts)}
              >
                <AccordionSummary>
                  <Trans comment="Análisis de estaciones - Título de acordión">
                    Niveles de Alerta
                  </Trans>
                </AccordionSummary>
                <AccordionDetails>
                  <SidebarAlertLevelsCheckboxList />
                </AccordionDetails>
              </SubAccordion>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      {telemetry.availableFields.includes(MetricField.Energy) && (
        <Box mb={2}>
          <Accordion
            expanded={expanded === Expanded.Energy}
            onChange={handleChangeExpanded(Expanded.Energy)}
          >
            <AccordionSummary>
              <Typography>
                <Trans comment="Análisis de estaciones - Título de acordión">Energía</Trans>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <SidebarEnergyCheckboxList />
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      <Box mb={2}>
        <Accordion
          expanded={expanded === Expanded.Advanced}
          onChange={handleChangeExpanded(Expanded.Advanced)}
        >
          <AccordionSummary>
            <Typography>
              <Trans comment="Análisis de estaciones - Título de acordión">Avanzado</Trans>
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <SidebarAdvancedCheckboxList />
            <Box p={2}>
              <SidebarPollingInterval />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default Sidebar;
