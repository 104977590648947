// Styles for tabs

const tabStyle = {
  tab: {
    borderBottom: '2px solid #ababab',
    backgroundColor: '#343c47',
    borderLeft: '1px solid #515862',
    borderRight: '1px solid #515862',
    webkitTextFillColor: '#8d9aab',
    textTransform: 'capitalize',
  },
  singleTab: {
    borderTop: '4px solid #ababab',
    WebkitTextFillColor: '#ffffff',
    textTransform: 'capitalize',
  },
  selectedTab: {
    borderTop: '4px solid #ababab',
    borderLeft: '1px solid #ccc',
    borderRight: '1px solid #ccc',
    WebkitTextFillColor: '#ffffff',
    textTransform: 'capitalize',
  },
  rightTab: {
    borderBottom: '2px solid #ababab',
    backgroundColor: '#343c47',
    borderLeft: '1px solid #515862',
    webkitTextFillColor: '#8d9aab',
    textTransform: 'capitalize',
  },
  selectedRightTab: {
    borderTop: '4px solid #ababab',
    borderLeft: '1px solid #ccc',
    WebkitTextFillColor: '#ffffff',
    textTransform: 'capitalize',
  },
  leftTab: {
    borderBottom: '2px solid #ababab',
    backgroundColor: '#343c47',
    borderRight: '1px solid #515862',
    webkitTextFillColor: '#8d9aab',
    textTransform: 'capitalize',
  },
  selectedLeftTab: {
    borderTop: '4px solid #ababab',
    borderRight: '1px solid #ccc',
    WebkitTextFillColor: '#ffffff',
    textTransform: 'capitalize',
  },
}

export { tabStyle };
