import { gql } from '@/__generated__';

const GET_ACCESS_DEVICES = gql(`#graphql
  query GetAccessDevices {
    authorization {
      authorizationGeneral {
        showOrganizationFilter
        showStatusDeviceFilter
        createDevice
        viewSourceInfo
      }
      authorizationDGA {
        enableDevices
      }
      authorizationSMA {
        updateInfoSMA
      }
    }
  }
`);

export default GET_ACCESS_DEVICES;
