import { useMutation, useSuspenseQuery } from '@apollo/client';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { FC, Suspense, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ActionButtons from 'src/components/ActionButtons';
import Loading from 'src/components/Loading';
import { CheckoutData, FormInputDate, FormInputText } from 'src/components/form/';
import { FormCheckbox } from 'src/components/form/FormCheckbox';
import ImagesNews from 'src/components/upload/ImagesNews';
import { HeaderDeviceConfiguration } from 'src/features/createDeviceConfiguration/components';
import {
  defaultFormValues,
  initialStateOptions,
} from 'src/features/createDeviceConfiguration/data/initialData';
import CREATE_DEVICE_PROFILE from 'src/features/createDeviceConfiguration/graphql/mutations/createProfile';
import useHardwareOptions from 'src/features/createDeviceConfiguration/hooks/useHardwareOptions';
import { tabStyle } from 'src/features/createDeviceConfiguration/styles/styles';
import { FormValues } from 'src/features/createDeviceConfiguration/type/FormValues';
import DEVICE_PROFILE from 'src/graphql/querys/deviceProfile';
import FETCH_DEVICE_PROFILE from 'src/graphql/querys/fetchDeviceProfile';
import { useModalStatusMessage, useUploadS3Images } from 'src/hooks';
import * as yup from 'yup';
import GET_UPDATE_HARDWARE_CONFIGURATION_DATA, {
  Device,
} from '../graphql/querys/getUpdateHardwareConfigurationData';
import OptionSelector from './OptionSelector';
import { useDispatch, useSelector } from 'src/store';
import { setSaveHardwareConfigurationModal } from 'src/slices/modals';
import { Access, AuthorizationWrapper } from 'src/features/authorization';

const useYupValidationResolver = (validationSchema) =>
  useCallback(
    async (data) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors, currentError) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message,
              },
            }),
            {},
          ),
        };
      }
    },
    [validationSchema],
  );
const validationSchema = yup.object({
  googleDriveUrl: yup.string().nullable().url('Debe ser una URL válida'),
  phoneNumber: yup
    .string()
    .nullable()
    .matches(
      /^(\+?56)?(\s?)(0?9)(\s?)[98765432]\d{7}$|^$/,
      'Debe ser un número de teléfono válido (ej: +56912345678 ó 912345678)',
    ),
});

interface Props {
  deviceId: string;
  onClose: () => void;
}

const isIntegration = (device: Device): Boolean => {
  return device?.profile?.source === 'integration';
};

const Component: FC<Props> = ({ deviceId, onClose: handleClose }) => {
  const {
    data: { device },
  } = useSuspenseQuery(GET_UPDATE_HARDWARE_CONFIGURATION_DATA, {
    variables: {
      deviceId,
    },
  });

  const [check, setCheck] = useState(initialStateOptions);
  const [tabValue, setTabValue] = useState(0);
  const { uploadS3Images } = useUploadS3Images('amaru-news');
  const resolver = useYupValidationResolver(validationSchema);
  const { options: sensorLevelOptions } = useHardwareOptions('level_sensor');
  const { options: sensorVelocityOptions } = useHardwareOptions('velocity_sensor');
  const { options: sensorFlowOptions } = useHardwareOptions('flow_sensor');
  const { options: sensorAngleOptions } = useHardwareOptions('angle_sensor');
  const { options: energizationOptions } = useHardwareOptions('energization');
  const { options: powerSupplyVoltageOptions } = useHardwareOptions('power_supply');
  const { options: batteryCapacityOptions } = useHardwareOptions('battery_capacity');
  const { options: batteryVoltageOptions } = useHardwareOptions('battery_voltage');
  const { options: technologyOptions } = useHardwareOptions('battery_tech');
  const { options: cpuOptions } = useHardwareOptions('cpu');
  const { options: auxiliaryOptions } = useHardwareOptions('auxiliary_plate');
  const { options: internetConnectionOptions } = useHardwareOptions('internet_connection');
  const { options: simModelOptions } = useHardwareOptions('sim_model');
  const { options: mobileOperatorOptions } = useHardwareOptions('mobile_operator');
  const { options: dataPlanMobileOptions } = useHardwareOptions('data_plan_type');
  const { options: antennaOptions } = useHardwareOptions('antenna_model');
  const { options: satellitalTypeOptions } = useHardwareOptions('satellital_type');
  const { options: dataConnectionOptions } = useHardwareOptions('data_connection_type');

  const classes = tabStyle;
  const [createProfile, { loading, error }] = useMutation(CREATE_DEVICE_PROFILE, {
    refetchQueries: [FETCH_DEVICE_PROFILE, DEVICE_PROFILE],
  });

  const { openModalErrorMessage, openModalSuccessMessage } = useModalStatusMessage();
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<FormValues>({ resolver });
  const [energization, internetConnection, mobileOperator, satellitalType] = watch([
    'energizationOption',
    'internetConnectionOption',
    'mobileOperatorOption',
    'satellitalTypeOption',
  ]);

  useEffect(() => {
    if (device) {
      // TODO: SOLVE TYPE ERROR
      reset(
        device?.hardwareConfiguration !== null
          ? {
              ...device.hardwareConfiguration,
              images: device?.hardwareConfiguration?.images
                ? { url: device?.hardwareConfiguration?.images ?? '' }
                : null,
              nameProject: device?.profile?.project ?? '',
              dateAction: device?.hardwareConfiguration?.dateAction
                ? new Date(device?.hardwareConfiguration?.dateAction)
                : new Date(),
              sensors:
                device?.sensors.map((sensor) => ({
                  sensorModelOption: sensor?.configuration?.sensorModelOption ?? null,
                  serialNumber: sensor?.configuration?.serialNumber ?? '',
                  modbusId: sensor?.configuration?.modbusId ?? '',
                  batchNumber: sensor?.configuration?.batchNumber ?? '',
                })) ?? [],
            }
          : { ...defaultFormValues },
      );
      const optionsCheck = {
        powerSupply: device?.hardwareConfiguration?.powerSupplyVoltageOption ? true : false,
      };
      setCheck(optionsCheck);
    }
  }, [device, reset]);

  const handleCloseDialog = () => {
    setCheck(initialStateOptions);
    setTabValue(0);
    handleClose();
    reset();
  };

  const onSubmit = async (vars: FormValues) => {
    let photoUrl = vars.images?.url ?? '';
    const img = vars?.images?.file ?? null;
    if (img) {
      photoUrl = await uploadS3Images(img);
    }
    try {
      const input = {
        images: photoUrl,
        googleDriveUrl: vars.googleDriveUrl,
        deviceId: device?.id,
        nameProject: vars.nameProject,
        dateAction: vars.dateAction,
        sensorLevel: vars.sensorLevelOption?.id ?? null,
        serialNumberLevel: vars.serialNumberLevel,
        modbusIdLevel: vars.modbusIdLevel,
        batchNumberLevelSensor: vars.batchNumberLevelSensor,
        sensorVelocity: vars.sensorVelocityOption?.id ?? null,
        serialNumberVelocity: vars.serialNumberVelocity,
        modbusIdVelocity: vars.modbusIdVelocity,
        batchNumberVelocitySensor: vars.batchNumberVelocitySensor,
        sensorFlow: vars.sensorFlowOption?.id ?? null,
        serialNumberFlow: vars.serialNumberFlow,
        modbusIdFlow: vars.modbusIdFlow,
        batchNumberFlowSensor: vars.batchNumberFlowSensor,
        energization: vars.energizationOption?.id ?? null,
        powerSupplyVoltage: vars.powerSupplyVoltageOption?.id ?? null,
        powerSupplyModel: vars.powerSupplyModel,
        powerSupplySerialNumber: vars.powerSupplySerialNumber,
        powerSupplyBrand: vars.powerSupplyBrand,
        solarPanelsControllers: vars.solarPanelsControllers,
        numberSolarPanelsControllers: vars.numberSolarPanelsControllers,
        quantityBattery: vars.quantityBattery,
        modelBattery: vars.modelBattery,
        technology: vars.technologyOption?.id ?? null,
        battery: vars.batteryCapacityOption?.id ?? null,
        voltage: vars.batteryVoltageOption?.id ?? null,
        quantitySun: vars.quantitySun,
        modelsSun: vars.modelsSun,
        watts: vars.watts,
        numberSun: vars.numberSun,
        cpu: vars.cpuOption?.id ?? null,
        batchNumberCpu: vars.batchNumberCpu,
        auxiliary: vars.auxiliaryOption?.id ?? null,
        numberAuxiliary: vars.numberAuxiliary,
        batchNumberAuxiliary: vars.batchNumberAuxiliary,
        internetConnection: vars.internetConnectionOption?.id ?? null,
        modelsConnection: vars.modelsConnectionOption?.id ?? null,
        imei: vars.imei,
        approved: vars.approved,
        mobileOperator: vars.mobileOperatorOption?.id ?? null,
        phoneNumber: vars.phoneNumber,
        dataPlanType: vars.dataPlanMobileOption?.id ?? null,
        iccid: vars.iccid,
        antenna: vars.antennaOption?.id ?? null,
        mac: vars.mac,
        configurationIp: vars.configurationIp,
        administrator: vars.administrator,
        networkName: vars.networkName,
        observation: vars.observation,
        lockNumber: vars.lockNumber,
        ip: vars.ip,
        imageVersion: vars.imageVersion,
        idEmnify: vars.idEmnify,
        satellitalType: vars.satellitalTypeOption?.id ?? null,
        satellitalSv: vars.satellitalSv,
        satellitalHv: vars.satellitalHv,
        satellitalHardwareName: vars.satellitalHardwareName,
        satellitalSerialName: vars.satellitalSerialName,
        codeNumberWidgetlord: vars.codeNumberWidgetlord,
        batchNumberWidgetlord: vars.batchNumberWidgetlord,
        serialNumberWidgetlord: vars.serialNumberWidgetlord,
        batchNumberSimCard: vars.batchNumberSimCard,
        dataConnectionType: vars.dataConnectionOption?.id ?? null,
        sensors: vars.sensors
          ? vars.sensors.map((sensor, index) => ({
              sensorId: device?.sensors[index]?.id,
              sensorModel: sensor.sensorModelOption?.id ?? null,
              serialNumber: sensor.serialNumber,
              modbusId: sensor.modbusId,
              batchNumber: sensor.batchNumber,
            }))
          : [],
        usbExtraction: vars.usbExtraction,
      };

      const result = await createProfile({
        variables: {
          input: input,
        },
      });
      if (result.data.createDeviceProfile.success) {
        handleCloseDialog();
        const successMsg =
          device?.hardwareConfiguration !== null
            ? 'Configuración actualizada correctamente.'
            : 'Configuración creada correctamente.';
        openModalSuccessMessage(successMsg);
      } else {
        openModalErrorMessage('Error, vuelve a intentarlo.');
      }
    } catch (e) {
      openModalErrorMessage('Error, vuelve a intentarlo.');
    }
  };

  const handleTabValue = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (loading) {
    return (
      <>
        <HeaderDeviceConfiguration device={device} />
        <Divider />
        <DialogContent>
          <Loading />
        </DialogContent>
      </>
    );
  }
  if (error) {
    return (
      <>
        <HeaderDeviceConfiguration device={device} />
        <Divider />
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h6" color="error">
                {error.message}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
      </>
    );
  }

  return (
    <>
      <HeaderDeviceConfiguration device={device} />
      <Divider />
      <Grid
        sx={{
          position: 'sticky',
          top: 0,
          zIndex: 1,
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabValue}
          variant="fullWidth"
          centered
          sx={{
            width: '100%',
          }}
          TabIndicatorProps={{
            sx: {
              display: 'none',
            },
          }}
        >
          <Tab
            label="General"
            sx={{
              ...(isIntegration(device)
                ? classes.singleTab
                : tabValue === 0
                ? classes.selectedLeftTab
                : classes.leftTab),
            }}
          />
          {!isIntegration(device) && (
            <Tab
              label="Sensorización"
              sx={{
                ...(tabValue === 1 ? classes.selectedTab : classes.tab),
              }}
            />
          )}
          {!isIntegration(device) && (
            <Tab
              label="Energización"
              sx={{
                ...(tabValue === 2 ? classes.selectedTab : classes.tab),
              }}
            />
          )}
          {!isIntegration(device) && (
            <Tab
              label="Data logger"
              sx={{
                ...(tabValue === 3 ? classes.selectedTab : classes.tab),
              }}
            />
          )}
          {!isIntegration(device) && (
            <Tab
              label="Conectividad"
              sx={{
                ...(tabValue === 4 ? classes.selectedRightTab : classes.rightTab),
              }}
            />
          )}
        </Tabs>
      </Grid>

      <DialogContent
        sx={{
          height: '60vh',
          justifyContent: 'center',
        }}
      >
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
          {/* GENERAL */}

          {tabValue === 0 && (
            <Grid item xs={12} sm={8} p={2} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12} mb={3}>
                  <Controller
                    name="images"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ImagesNews
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const file = e.target.files;
                          if (file && Object.keys(file).length > 0) {
                            const url = URL.createObjectURL(file[0]);
                            onChange({ url, file: file[0] });
                          }
                        }}
                        onDelete={() => {
                          onChange(null);
                        }}
                        images={value}
                        title="Selecciona una imagen o GIF para representar el dispositivo (opcional)"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6} mt={2}>
                  <FormInputText name="googleDriveUrl" control={control} label="Google Drive URL" />
                </Grid>
                <Grid item xs={12} sm={6} mt={2}>
                  <FormInputText name="nameProject" control={control} label="Proyecto *" />
                </Grid>
                <Grid item xs={12} sm={6} mt={2}>
                  <FormInputText
                    name="lockNumber"
                    control={control}
                    label="Número de chapa / llave"
                  />
                </Grid>
                <Grid item xs={12} sm={6} mt={2}>
                  <FormInputDate
                    name="dateAction"
                    control={control}
                    label="Fecha de activación *"
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <FormInputText
                    name="observation"
                    control={control}
                    label="Observaciones generales"
                    multiline
                    minRows={4}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* SENSORIZACION */}
          {tabValue === 1 && (
            <Grid item xs={12} sm={8} p={2} mt={2}>
              {!device.profile.type.includes('LG') ? (
                <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                  {device?.deviceTypeMeasurement?.level == 1 && (
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <OptionSelector
                            options={sensorLevelOptions}
                            label="Sensor de nivel *"
                            name="sensorLevelOption"
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText
                            name="serialNumberLevel"
                            control={control}
                            label="Número de serie del sensor de nivel *"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText
                            name="modbusIdLevel"
                            control={control}
                            label="Modbus ID del sensor de nivel"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText
                            name="batchNumberLevelSensor"
                            control={control}
                            label="Número de lote del sensor de nivel *"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {device?.deviceTypeMeasurement?.velocity == 1 && (
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <OptionSelector
                            options={sensorVelocityOptions}
                            label="Sensor de velocidad *"
                            name="sensorVelocityOption"
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText
                            name="serialNumberVelocity"
                            control={control}
                            label="Número de serie del sensor de velocidad *"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText
                            name="modbusIdVelocity"
                            control={control}
                            label="Modbus ID del sensor de velocidad"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText
                            name="batchNumberVelocitySensor"
                            control={control}
                            label="Número de lote del sensor de velocidad *"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  {device?.deviceTypeMeasurement?.flow == 1 && (
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <OptionSelector
                            label="Sensor de flujo *"
                            name="sensorFlowOption"
                            options={sensorFlowOptions}
                            control={control}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText
                            name="serialNumberFlow"
                            control={control}
                            label="Número de serie del sensor de flujo *"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText
                            name="modbusIdFlow"
                            control={control}
                            label="Modbus ID del sensor de flujo"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText
                            name="batchNumberFlowSensor"
                            control={control}
                            label="Número de lote del sensor de flujo *"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid container spacing={2} sx={{ justifyContent: 'center' }}>
                  {(device.sensors ?? []).map((sensor, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <Typography variant="h6" color="textSecondary" mt={2} ml={1}>
                        Sensor {index + 1} - {sensor?.type?.label}
                      </Typography>
                      <Grid item xs={12} mt={2}>
                        <OptionSelector
                          options={
                            sensor?.type?.name == 'level' ? sensorLevelOptions : sensorAngleOptions
                          }
                          label="Tipo de sensor *"
                          name={`sensors[${index}].sensorModelOption`}
                          control={control}
                        />
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <FormInputText
                          name={`sensors[${index}].serialNumber`}
                          control={control}
                          label="Número de serie del sensor"
                        />
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <FormInputText
                          name={`sensors[${index}].modbusId`}
                          control={control}
                          label="Modbus ID del sensor"
                        />
                      </Grid>
                      <Grid item xs={12} mt={2}>
                        <FormInputText
                          name={`sensors[${index}].batchNumber`}
                          control={control}
                          label="Número de lote del sensor"
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          )}

          {/* ENERGIZACION */}
          {tabValue === 2 && (
            <Grid item xs={12} sm={6} p={2} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <OptionSelector
                    options={energizationOptions}
                    label="Tipo de Energía *"
                    name="energizationOption"
                    control={control}
                  />
                </Grid>
                {(energization?.name === 'Solar' ||
                  energization?.name === 'Red 220VAC y Solar') && (
                  <>
                    <Typography variant="h6" color="textSecondary" mt={2} ml={2}>
                      Controlador Solar
                    </Typography>
                    <Grid item xs={12} mt={2}>
                      <FormInputText
                        name="solarPanelsControllers"
                        control={control}
                        label="Modelo controlador solar *"
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <FormInputText
                        name="numberSolarPanelsControllers"
                        control={control}
                        label="Número de serie del controlador solar *"
                      />
                    </Grid>
                    <Typography variant="h6" color="textSecondary" mt={2} ml={2}>
                      Panel Solar
                    </Typography>
                    <Grid item xs={12} mt={2}>
                      <FormInputText
                        name="quantitySun"
                        control={control}
                        label="Cantidad de paneles solares *"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <FormInputText
                        name="modelsSun"
                        control={control}
                        label="Modelo del panel solar *"
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <FormInputText name="watts" control={control} label="Potencia[W]" />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <FormInputText
                        name="numberSun"
                        control={control}
                        label="Número de serie del panel solar *"
                      />
                    </Grid>
                  </>
                )}

                {energization && energization?.name !== 'Red 220VAC' && (
                  <>
                    <Typography variant="h6" color="textSecondary" mt={2} ml={2}>
                      Batería
                    </Typography>
                    <Grid item xs={12} mt={2}>
                      <FormInputText
                        name="quantityBattery"
                        control={control}
                        label="Cantidad de baterías *"
                        type="number"
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <FormInputText
                        name="modelBattery"
                        control={control}
                        label="Marca o modelo de batería"
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <OptionSelector
                        options={technologyOptions}
                        label="Tecnología *"
                        name="technologyOption"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <OptionSelector
                        options={batteryCapacityOptions}
                        label="Capacidad de la batería *"
                        name="batteryCapacityOption"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <OptionSelector
                        options={batteryVoltageOptions}
                        label="Voltage del sistema *"
                        name="batteryVoltageOption"
                        control={control}
                      />
                    </Grid>
                  </>
                )}
                {energization?.name === 'Solar' && (
                  <Grid item xs={12} mt={2}>
                    <CheckoutData
                      check={check}
                      setCheck={setCheck}
                      title="¿Dispositivo tiene fuente AC/DC?"
                      name="powerSupply"
                    />
                  </Grid>
                )}
                {(check?.powerSupply === true ||
                  (energization && energization?.name !== 'Solar')) && (
                  <>
                    <Typography variant="h6" color="textSecondary" mt={2} ml={2}>
                      Fuente {energization?.name !== 'Solar' ? 'DC/DC' : 'AC/DC'}
                    </Typography>

                    <Grid item xs={12} mt={2}>
                      <OptionSelector
                        options={powerSupplyVoltageOptions}
                        label="Voltage *"
                        name="powerSupplyVoltageOption"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <FormInputText name="powerSupplyModel" control={control} label="Modelo" />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <FormInputText
                        name="powerSupplySerialNumber"
                        control={control}
                        label="Número de serie *"
                      />
                    </Grid>
                    <Grid item xs={12} mt={2}>
                      <FormInputText name="powerSupplyBrand" control={control} label="Marca" />
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          )}

          {/* DATA LOGGER */}
          {tabValue === 3 && (
            <Grid item xs={12} sm={6} p={2} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <OptionSelector
                    options={cpuOptions}
                    label="CPU central *"
                    name="cpuOption"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInputText
                    name="batchNumberCpu"
                    control={control}
                    label="Número de lote CPU *"
                  />
                </Grid>
                <Grid item xs={12}>
                  <OptionSelector
                    options={auxiliaryOptions}
                    label="Placa auxiliar *"
                    name="auxiliaryOption"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInputText
                    name="numberAuxiliary"
                    control={control}
                    label="Número de serie de la placa auxiliar *"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInputText
                    name="batchNumberAuxiliary"
                    control={control}
                    label="Número de lote placa auxiliar *"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInputText
                    name="serialNumberWidgetlord"
                    control={control}
                    label="Número de serie widgetlord"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInputText
                    name="batchNumberWidgetlord"
                    control={control}
                    label="Número de lote widgetlord"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInputText
                    name="codeNumberWidgetlord"
                    control={control}
                    label="Código widgetlord"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormInputText
                    name="imageVersion"
                    control={control}
                    label="Versión de la imagen *"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormCheckbox name="usbExtraction" control={control} label="Extracción USB" />
                </Grid>
              </Grid>
            </Grid>
          )}

          {/* CONECTIVIDAD */}
          {tabValue === 4 && (
            <Grid item xs={12} sm={6} p={2} mt={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <OptionSelector
                    options={dataConnectionOptions}
                    label="Método de conexión de datos  *"
                    name="dataConnectionOption"
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <OptionSelector
                    options={internetConnectionOptions}
                    label="Método de conexión a internet  *"
                    name="internetConnectionOption"
                    control={control}
                  />
                </Grid>
                {internetConnection?.name === 'Redes Móviles' && (
                  <>
                    <Grid item xs={12}>
                      <OptionSelector
                        options={simModelOptions}
                        label="Modelo *"
                        name="modelsConnectionOption"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText name="imei" control={control} label="IMEI *" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name="batchNumberSimCard"
                        control={control}
                        label="Número de lote módulo 4G*"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormCheckbox name="approved" control={control} label="Homologado?" />
                    </Grid>
                    <Grid item xs={12}>
                      <OptionSelector
                        options={mobileOperatorOptions}
                        label="Operador móvil *"
                        name="mobileOperatorOption"
                        control={control}
                      />
                    </Grid>
                    {mobileOperator?.name === 'Emnify' && (
                      <Grid item xs={12}>
                        <FormInputText name="idEmnify" control={control} label="ID Emnify *" />
                      </Grid>
                    )}
                    {(mobileOperator?.name === 'Emnify' || mobileOperator?.name === 'Mint') && (
                      <>
                        <Grid item xs={12}>
                          <FormInputText
                            name="phoneNumber"
                            control={control}
                            label="Número de Teléfono"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <OptionSelector
                            options={dataPlanMobileOptions}
                            label="Plan de datos"
                            name="dataPlanMobileOption"
                            control={control}
                          />
                        </Grid>
                      </>
                    )}
                    <Grid item xs={12}>
                      <FormInputText name="iccid" control={control} label="ICCID *" />
                    </Grid>
                    <Grid item xs={12}>
                      <OptionSelector
                        options={antennaOptions}
                        label="Modelo antena *"
                        name="antennaOption"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText name="ip" control={control} label="Dirección IP" />
                    </Grid>
                  </>
                )}
                {(internetConnection?.name === 'Red Cableada' ||
                  internetConnection?.name === 'Red Inalámbrica') && (
                  <>
                    <Grid item xs={12}>
                      <FormInputText name="mac" control={control} label="MAC *" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name="configurationIp"
                        control={control}
                        label="Configuración IP *"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name="administrator"
                        control={control}
                        label="Administrador *"
                      />
                    </Grid>
                  </>
                )}
                {internetConnection?.name === 'Red Inalámbrica' && (
                  <>
                    <Grid item xs={12}>
                      <FormInputText name="networkName" control={control} label="Nombre Red *" />
                    </Grid>
                  </>
                )}
                {internetConnection?.name === 'Internet Satelital' && (
                  <>
                    <Grid item xs={12}>
                      <FormInputText name="iccid" control={control} label="ICCID *" />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText name="imei" control={control} label="IMEI *" />
                    </Grid>
                    <Grid item xs={12}>
                      <OptionSelector
                        options={satellitalTypeOptions}
                        label="Tipo *"
                        name="satellitalTypeOption"
                        control={control}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name="phoneNumber"
                        control={control}
                        label="Número de Teléfono"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormInputText
                        name="satellitalHardwareName"
                        control={control}
                        label="Nombre del hardware"
                      />
                    </Grid>
                    {satellitalType?.name === 'Rock Remote' && (
                      <>
                        <Grid item xs={12}>
                          <FormInputText
                            name="satellitalSerialName"
                            control={control}
                            label="Serial de hardware"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText
                            name="satellitalSv"
                            control={control}
                            label="Versión de software"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <FormInputText
                            name="satellitalHv"
                            control={control}
                            label="Versión de hardware"
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions
        sx={{
          justifyContent: 'center',
          padding: '20px 24px',
        }}
      >
        <Grid container sx={{ justifyContent: 'center', alignItems: 'center' }}>
          <Grid item xs={12} sm={8} p={2}>
            <ActionButtons
              closeFunction={handleCloseDialog}
              actionFunction={handleSubmit(onSubmit)}
              actionButtonText={device?.hardwareConfiguration !== null ? 'Actualizar' : 'Crear'}
              actionButtonColor="success"
              disabledAction={!isDirty}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

const Fallback: FC = () => null;

const SaveHardwareConfigurationModal: FC = () => {
  const dispatch = useDispatch();
  const { saveHardwareConfigurationModal: modal } = useSelector((state) => state.modals_store);

  const onClose = () => {
    dispatch(
      setSaveHardwareConfigurationModal({
        open: false,
      }),
    );
  };

  return (
    <Dialog open={modal.open} maxWidth="xl" fullWidth>
      <Suspense fallback={<Fallback />}>
        <AuthorizationWrapper onClose={onClose} access={Access.SaveHardwareConfigurationModal}>
          {modal.open && <Component onClose={onClose} deviceId={modal.deviceId} />}
        </AuthorizationWrapper>
      </Suspense>
    </Dialog>
  );
};

export default SaveHardwareConfigurationModal;
