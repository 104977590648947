import { gql } from '@/__generated__';

const GET_SMA_REPORTS = gql(`#graphql
query GetSmaReports_SmaSuccessTable($inputDevice: DeviceInput!, $inputReports: SmaReportsInput!, $inputSmaConfig: SmaConfigInput) {
  device(input: $inputDevice) {
    id
    profile {
      id
      name
    }
    smaConfig(input: $inputSmaConfig) { 
      id
      reports(input: $inputReports) {
        items {
          timestamp
          success
        }
      }
    }
  }
}
`);

export default GET_SMA_REPORTS;
