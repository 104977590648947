// apollo client
import { gql } from '@apollo/client';

const CREATE_DEVICE_PROFILE = gql`
	mutation CreateDeviceProfile(
		$input: CreateDeviceProfileInput!
	) {
		createDeviceProfile(
			input: $input
		) {
			success
			message
		}
	}
`;

export default CREATE_DEVICE_PROFILE;
