import { DayOfWeek, DgaFrequency, DgaType } from '@/__generated__/graphql';
import { Formatters } from '@/hooks/useFormatters';
import { I18n } from '@lingui/core';
import { t } from '@lingui/macro';

const getWeekdayTranslation = (value: DayOfWeek, i18n: I18n) => {
  if (value === DayOfWeek.Monday) return t(i18n)`Lunes`;
  if (value === DayOfWeek.Tuesday) return t(i18n)`Martes`;
  if (value === DayOfWeek.Wednesday) return t(i18n)`Miércoles`;
  if (value === DayOfWeek.Thursday) return t(i18n)`Jueves`;
  if (value === DayOfWeek.Friday) return t(i18n)`Viernes`;
  if (value === DayOfWeek.Saturday) return t(i18n)`Sábado`;
  if (value === DayOfWeek.Sunday) return t(i18n)`Domingo`;
  return '';
};

export const getDGAFrequencyTranslation = (
  frequency: DgaFrequency,
  frequencyInfo: {
    hour?: number | null;
    weekday?: DayOfWeek | null;
    day?: number | null;
  },
  i18n: I18n,
  formatDateTime: Formatters['formatDateTime'],
): string => {
  const weekday = frequencyInfo.weekday ? getWeekdayTranslation(frequencyInfo.weekday, i18n) : '';
  const hour = frequencyInfo.hour ?? 0;
  const hourString = formatDateTime(new Date(0, 0, 0, hour), (options) => ({
    hour12: options.hour12,
    hour: '2-digit',
    minute: 'numeric',
  }));
  switch (frequency) {
    case DgaFrequency.Daily:
      return t(i18n)`Diaria (${hourString})`;
    case DgaFrequency.Weekly:
      return t(i18n)`Semanal (${weekday} ${hourString})`;
    case DgaFrequency.Monthly:
      return t(i18n)`Mensual (día ${frequencyInfo.day} a las ${hourString})`;
    case DgaFrequency.Hourly:
      return t(i18n)`Cada hora`;
    default:
      return '';
  }
};

export const getDGATypeTranslation = (type: DgaType | null | undefined, i18n: I18n) => {
  switch (type) {
    case DgaType.Surface:
      return t(i18n)`Superficial`;
    case DgaType.PipeSurface:
      return t(i18n)`Superficial con flujómetro`;
    case DgaType.Underground:
      return t(i18n)`Subterránea`;
    default:
      return '';
  }
};
