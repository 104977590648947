import { gql } from '@/__generated__';
import { GetNotificationsQuery } from '@/__generated__/graphql';

export type Notification = GetNotificationsQuery['notifications'][number];
export type NotificationEntity = Notification['notificationObject']['entity'];

const GET_NOTIFICATIONS = gql(`#graphql
  query GetNotifications {
    notifications {
      id
      status
      updatedAt
      notificationObject {
        id
        status
        entity {
          __typename
          ... on Announcement {
            id
            title
            createdAt
            description
          }
          ... on AlertNotification {
            id
            message
            device {
              id
              profile {
                name
              }
            }
          }
        }
      }
    }
  }
`);

export default GET_NOTIFICATIONS;
