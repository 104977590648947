import { gql } from '@/__generated__';

const GET_SMA_REPORTS = gql(`#graphql
query DeviceList_SmaReports($inputDevice: DeviceInput!, $inputReports: SmaReportsInput, $inputSmaConfig: SmaConfigInput, $inputPagination: DynamoPaginationInput) {
  device(input: $inputDevice) {
    id
    profile {
      id
      name
    }
    smaConfig(input: $inputSmaConfig) { 
      id
      reports(input: $inputReports, pagination: $inputPagination) {
        items {
          timestamp
          success
          smaMetricUnit
          smaMetricValue
          smaResponseMessage
          smaResponseVerificationId
        }
        pageInfo {
          count
          lastEvaluatedKey
        }
      }
    }
  }
}
`);

export default GET_SMA_REPORTS;
