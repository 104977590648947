import { gql } from '@/__generated__';

const NOTIFICATION_CREATED = gql(`#graphql
  subscription NotificationCreated {
    notificationCreated {
      notification {
        id
        status
        updatedAt
        notificationObject {
          id
          status
          entity {
            __typename
            ... on Announcement {
              id
              title
              createdAt
              description
            }
            ... on AlertNotification {
              id
              message
              device {
                id
                profile {
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`);

export default NOTIFICATION_CREATED;
