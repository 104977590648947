import { Grid, Typography, Button, Box } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';

const ImagesNews = ({ onChange, images, title, onDelete }) => {
  return (
    <Grid container display="flex" justifyContent="center">
      <Grid
        item
        xs={12}
        md={6}
        display="flex"
        justifyContent="center"
        mt={2}
        sx={{
          position: 'relative',
        }}
      >
        <input
          type="file"
          name="file"
          onChange={onChange}
          style={{
            position: 'absolute',
            cursor: 'pointer',
            minHeight: '100%',
            opacity: '0',
            width: '100%',
          }}
        />
        {images && images.url ? (
          <>
            <img
              src={images.url}
              alt="images"
              style={{
                border: '4px solid #3165786b',
                borderRadius: 10,
                maxWidth: 100,
              }}
            />
          </>
        ) : (
          <>
            <PublishIcon
              sx={{
                fontSize: '70px',
                border: '2px solid white',
                borderRadius: '4px',
              }}
            />
          </>
        )}
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        p={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {images && (
          <Box mb={2}>
            <Button
              onClick={onDelete}
              variant="contained"
              color="secondary"
              size="small"
            >
              Eliminar
            </Button>
          </Box>
        )}

        <Typography color="textSecondary">{title}</Typography>
      </Grid>
    </Grid>
  );
};

export default ImagesNews;
