import { Box, Collapse, IconButton, LinearProgress, Link, Typography, styled } from '@mui/material';
import { useState, type FC } from 'react';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { useSelector } from '@/store';
import { Trans } from '@lingui/macro';

const BorderLinearProgress = styled(LinearProgress)(() => ({
  height: 14,
  borderRadius: 5,
}));

const GateMovementBanner: FC = () => {
  const { gatesInMovement } = useSelector((state) => state.gates_in_movement_store);
  const [collapsed, setCollapsed] = useState(false);

  // dummy data
  // const gatesInMovement = [
  //   {
  //     gateId: 'catemu-alto',
  //     gateName: 'Catemu Alto - Bocatoma - Compuerta N',
  //     position: 35,
  //     startPosition: 0,
  //     finishPosition: 200,
  //     state: 'STARTED_MOVING',
  //   },
  //   {
  //     gateId: 'catemu-alto',
  //     gateName: 'Catemu Alto - Bocatoma - Compuerta N',
  //     position: 35,
  //     startPosition: 0,
  //     finishPosition: 200,
  //     state: 'MOVING',
  //   },
  //   {
  //     gateId: 'catemu-alto',
  //     gateName: 'Catemu Alto - Bocatoma - Compuerta N',
  //     position: 200,
  //     startPosition: 0,
  //     finishPosition: 200,
  //     state: 'FINISHED_MOVING',
  //   },
  //   {
  //     gateId: 'catemu-alto',
  //     gateName: 'Catemu Alto - Bocatoma - Compuerta N',
  //     position: 35,
  //     startPosition: 0,
  //     finishPosition: 200,
  //     state: 'FINISHED_MOVING',
  //   },
  // ];

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'warning.main',
        color: 'black',
        textAlign: 'center',
      }}
    >
      <Collapse collapsedSize={gatesInMovement.length === 0 ? 0 : 25} in={!collapsed}>
        {gatesInMovement.map((gateInMovement, index) => {
          let message = <></>;
          const { gateId, gateName, position, startPosition, finishPosition } = gateInMovement;

          // const roundedPosition = Math.round(position * 100) / 100;
          const roundedFinishPosition = Math.round(finishPosition * 100) / 100;
          const roundedStartPosition = Math.round(startPosition * 100) / 100;
          const error =
            gateInMovement.state === 'FINISHED_MOVING' && Math.abs(finishPosition - position) > 0.5;

          if (gateInMovement.state === 'STARTED_MOVING')
            message = (
              <Trans>
                !ATENCIÓN! La compuerta <b>{gateName}</b> ha comenzado a moverse
              </Trans>
            );
          if (gateInMovement.state === 'MOVING')
            message = (
              <Trans>
                La compuerta <b>{gateName}</b> se está moviendo
              </Trans>
            );
          if (gateInMovement.state === 'FINISHED_MOVING')
            message = (
              <>
                <Trans>La compuerta</Trans> <b>{gateName}</b>{' '}
                {error ? <Trans>ha sido detenida</Trans> : <Trans>ha terminado de moverse</Trans>}
              </>
            );

          const progressLabel = (
            <>
              <Trans>Posición inicial</Trans>: {roundedStartPosition} cm -{' '}
              <Trans>Posición objetivo</Trans>: {roundedFinishPosition} cm
            </>
          );
          const positionPercent =
            (Math.abs(position - startPosition) / Math.abs(finishPosition - startPosition)) * 100;

          return (
            <Box
              display="flex"
              gap={2}
              alignItems="center"
              justifyContent="center"
              px={2}
              height={26}
              borderBottom={index === gatesInMovement.length - 1 ? 0 : 1}
              // darken the background color depending on the index
              bgcolor={`rgba(0, 0, 0, ${Math.min(index, 4) * 0.12})`}
            >
              <Typography noWrap>{message} </Typography>
              <Box display="flex" alignItems="center" gap={1}>
                <Box width={100}>
                  <BorderLinearProgress
                    variant={
                      gateInMovement.state === 'STARTED_MOVING' ? 'indeterminate' : 'determinate'
                    }
                    value={positionPercent}
                    // color={
                    //   gateInMovement.state === 'FINISHED_MOVING'
                    //     ? Math.abs(finishPosition - position) < 0.5
                    //       ? 'success'
                    //       : 'error'
                    //     : 'primary'
                    // }
                  />
                </Box>
                {gateInMovement.state !== 'STARTED_MOVING' && (
                  <Typography variant="body2" color="#222" noWrap>
                    {Math.round(positionPercent)}%
                  </Typography>
                )}
              </Box>
              <Typography variant="body2" color="#222" noWrap>
                {progressLabel}
              </Typography>
              <Link
                href={`/gates/administration/${gateId}`}
                color="inherit"
                sx={{ fontWeight: 'bold' }}
                noWrap
              >
                <Trans>Ver más</Trans>
              </Link>
              {gatesInMovement.length > 1 && index === 0 && (
                <IconButton
                  size="small"
                  sx={{ color: 'black' }}
                  onClick={() => setCollapsed(!collapsed)}
                >
                  {collapsed ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
              )}
            </Box>
          );
        })}
      </Collapse>
    </Box>
  );
};

export default GateMovementBanner;
