import DashboardNavbar from '@components/layout/DashboardNavbar';
import DashboardSidebar from '@components/layout/DashboardSidebar';
import { Box } from '@mui/material';
import type { FC, ReactNode } from 'react';
import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet } from 'react-router-dom';
import ApolloErrorBoundary from 'src/components/ApolloErrorBoundary';
import { GateMovementBanner } from 'src/features';

interface DashboardLayoutProps {
  children?: ReactNode;
}

const DashboardLayout: FC<DashboardLayoutProps> = () => {
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);

  return (
    <>
      <Helmet>
        <title>Capta Hydro | Amaru</title>
      </Helmet>
      <Box
        height="100%"
        display="grid"
        gridTemplateColumns={{ xs: '1fr', lg: '250px 1fr' }}
        gridTemplateRows="auto 1fr"
      >
        <DashboardNavbar onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)} />
        <DashboardSidebar
          onMobileClose={(): void => setIsSidebarMobileOpen(false)}
          openMobile={isSidebarMobileOpen}
        />
        <Box height="100%" overflow="auto">
          <ApolloErrorBoundary>
            <Outlet />
          </ApolloErrorBoundary>
        </Box>
        <Box sx={{ gridColumn: '1 / span 2' }}>
          <GateMovementBanner />
        </Box>
      </Box>
    </>
  );
};

export default DashboardLayout;
